<template>
  <div class="grid grid-cols-12 gap-2">
    <!-- FILTROS -->
    <vx-card class="col-span-12">
      <questionnaire-event-filters
        v-model="filters"
        @questionnaireInput="clear"
        @clearFilters="clear"
        @fetch="reloadData"
        @shutdownAUTOLOAD="shutdownAutoload"
      />
    </vx-card>

    <!-- INFORMAÇÕES QUESTIONÁRIO -->
    <vx-card v-if="questionnaire" class="col-span-12">
      <questionnaire-information :questionnaire="questionnaire"/>
    </vx-card>

    <!-- INDICADORES NUMÉRICOS -->
    <vx-card v-if="questionnaire" class="col-span-12">
      <questionnaire-event-indicators
        :indicators="indicators"
      />
    </vx-card>

    <!-- GRÁFICO EVENTOS -->
    <vx-card v-if="chartData" class="col-span-12">
      <questionnaire-events-severity-graphic :data="chartData"/>
    </vx-card>

    <!-- TABELA RESPOSTAS/AVALIAÇÕES -->
    <vx-card v-show="questionnaire" class="col-span-12">
      <grid
        ref="grid"
        :service="questionnaireService"
        route_name="anythinghere"
        route_grid_path="/answers/events/grid"
        :order_column.sync="gridParams.column"
        defaultOrderColumn="user_name"
        :direction.sync="gridParams.direction"
        @grid-UPDATED="reloadingGrid = false"
        :sortKeys="{'event_count': null,'medium_time': null}"
        :column_formats="{
          user_name: (name) => name ? name : '< Não Cadastrado >',
          medium_time: (seconds) => {
            return seconds
          },
          event_count: (counts) => {
            const low = _.get(counts, 'low', null)
            const medium = _.get(counts, 'medium', null)
            const high = _.get(counts, 'high', null)

            let elementary = ''

            if (low !== null) elementary = elementary.concat(`<label class='_event-low'>${low}</label>`)
            if (medium !== null) elementary = elementary.concat(`<label class='_event-medium'>${medium}</label>`)
            if (high !== null) elementary = elementary.concat(`<label class='_event-high'>${high}</label>`)
            return `<div class='flex flex-row gap-2'>${elementary}</div>`
          },
          finished_at: (finished_at) => finished_at !== null ? finished_at : 'Em Andamento'
        }"
        :css_formats="{
          user_name: (name) => name ? name : 'text-warning',
          presence: (name) => name ? 'text-success' : 'text-danger'
        }"
        :hide_actions="false"
        :fetchOnMounted="false"
        :showLoading="false">
        <template v-slot:actions="actions">
        <!--
          <feather-icon
            v-if="questionnaireIsProctored && !questionnaireIsSessionFinished"
            v-permission="`proctoring.monitor`"
            icon="VideoIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            @click="openProctoringMonitor(actions.row)"
          />
          -->
          <feather-icon
            icon="EyeIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            @click="openDetails(actions.row)"
          />
        </template>
      </grid>
    </vx-card>
  </div>
</template>

<script>
import QuestionnaireEventService from '@/services/api/QuestionnaireEventService'
import QuestionnaireEventFilters from './QuestionnaireEventFilters.vue'
import QuestionnaireInformation from './QuestionnaireInformation.vue'
import Grid from '@/components/Grid.vue'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import QuestionnaireEventIndicators from './QuestionnaireEventIndicators.vue'
import QuestionnaireEventsSeverityGraphic from './QuestionnaireEventsSeverityGraphic.vue'

export default {
  components: { QuestionnaireEventFilters, QuestionnaireInformation, Grid, QuestionnaireEventIndicators, QuestionnaireEventsSeverityGraphic },

  data: () => ({
    filters: {
      questionnaire: null,
      user: [],
      severity: null,
      autoFilter: false,
    },
    gridParams: {
      direction: 'asc',
      column: 'user_name'
    },
    indicators: null,
    questionnaire: null,
    service: null,
    questionnaireService: null,
    chartData: null,
    autoloadInterval: null,
    autoloadCycle: null,
    listeningTo: null,
    reloadingData: false,
    reloadingGrid: false
  }),

  created() {
    this.service = QuestionnaireEventService.build(this.$vs)
    this.questionnaireService = ContentQuestionnaireService.build(this.$vs)
    this.autoloadCycle = this._.debounce((filters) => {

      const reloading = this.reloadingGrid && this.reloadingData

      if (!reloading) {
        this.reloadGrid(filters)
      }

      if (!reloading) {
        this.reloadChartIndicators(filters)
        this.reloadingData = true
      }

    }, 5000)
  },

  beforeDestroy() {
    console.log('Shutting down autoload')
    this.shutdownAutoload()
  },

  computed: {
    questionnaireIsProctored() {
      const activeProctoringStatus = this._.get(this.questionnaire, 'active_proctoring.status', 'D')
      return activeProctoringStatus === 'E'
    },
    questionnaireIsSessionFinished() {
      return this._.get(this.questionnaire, 'finished_session', false)
    }
  },

  methods: {
    leaveSocket() {
      window.Echo.leave(`content-questionnaire.${this.listeningTo}`)
      this.listeningTo = null
    },
    joinSocket(id, filters) {
      if (this.listeningTo) {
        this.leaveSocket()
      }

      if (this._.has(window, 'Echo') && id) {
        window.Echo.channel(`content-questionnaire.${id}`)
        .listen('.App\\Events\\QuestionnaireSessionChanged', (event) => {
          this.autoloadCycle(filters)
        })
        this.listeningTo = id
      }
    },
    clear() {
      this.indicators = null
      this.questionnaire = null
      this.chartData = null
    },
    openProctoringMonitor(answer) {
      const roomId = this._.get(answer, 'proctoring_room_id', null)
      if (roomId !== null) {
        const routeData = this.$router.resolve({
          name: 'proctoring_room',
          params: { roomId: roomId },
        })
        window.open(routeData.href, '_blank')
      } else {
        this.notifyError(this.$vs, 'Sala não encontrada')
      }
    },
    openDetails(answer) {
      if ('id' in answer && answer.id) {
        const routeData = this.$router.resolve({
          name: 'questionnaires_events_details',
          params: { id: answer.id },
        })
        window.open(routeData.href, '_blank')
      }
    },
    questionnaireQuestionsCount() {
      const data = this._.get(this.questionnaire, 'data', null)
      if (data !== null) {
        const decodedFields = this._.get(JSON.parse(data), 'fields', null)
        if (decodedFields !== null) return decodedFields.length
      }
    },
    reloadData(filters) {

      if (this.filters.questionnaire !== null) {
        this.$vs.loading()
        this.reloadGrid(filters)
        this.reloadQuestionnaire(filters)
        this.reloadChartIndicators(filters)
        this.$vs.loading.close()
        if(this.filters.autoFilter){
          this.bootAutoload(filters, 10000)
        }else{
          this.shutdownAutoload()
        }

      }else{
        this.shutdownAutoload()
      }

    },
    bootAutoload(filters, msTimeout) {
      if (this.autoloadInterval === null) {
        // this.joinSocket(this._.get(filters, 'questionnaire.id'), filters)
        this.autoloadInterval = setInterval(this.autoloadCycle, msTimeout, filters)
      } else {
        this.shutdownAutoload()
        this.bootAutoload(filters, msTimeout)
      }
    },
    shutdownAutoload() {
      // this.leaveSocket()
      clearInterval(this.autoloadInterval)
      this.autoloadInterval = null
    },
    reloadQuestionnaire(filters) {
      this.questionnaire = this._.get(filters, 'questionnaire')
    },
    reloadChartIndicators(filters) {
      const filtersExtracted = this.extractFilters(filters)
      if (filtersExtracted.questionnaire_id !== null) {
        this.questionnaireService.getEventData(filtersExtracted.questionnaire_id, filtersExtracted).then(
          (response) => {
            const chartData = this._.get(response, 'chart')
            const indicatorsData = this._.get(response, 'indicators')

            if (chartData) {
              this.chartData = chartData
            }

            if (indicatorsData) {
              this.indicators = indicatorsData
            }

            this.reloadingData = false
          },
          (error) => {
            this.notifyError(this.$vs, 'Erro! Não foi possível carregar alguns dados do questionário especificado.')
          }
        )
      }
    },
    reloadChart(filters) {
      const filtersExtracted = this.extractFilters(filters)
      if (filtersExtracted.questionnaire_id !== null) {
        this.questionnaireService.getEventSeverityChart(filtersExtracted.questionnaire_id, filtersExtracted).then(
          (response) => {
            this.chartData = response
          },
          (error) => {
            this.notifyError(this.$vs, 'Erro! Não foi possível carregar o gráfico de acompanhamento de eventos do questionário.')
          }
        )
      }
    },
    reloadIndicators(filters) {
      const filtersExtracted = this.extractFilters(filters)
      if (filtersExtracted.questionnaire_id !== null) {
        this.questionnaireService.getStatusIndicators(filtersExtracted.questionnaire_id, filtersExtracted).then(
          (response) => {
            this.indicators = response
          },
          (error) => {
            this.notifyError(this.$vs, 'Erro! Não foi possível carregar os indicadores do questionário.')
          }
        )
      }
    },
    extractFilters(filters) {
      const questionnaireId = this._.get(filters, 'questionnaire.id', null)
      const user = this._.get(filters, 'user', [])
      const users = user
      const severity = this._.get(filters, 'severity', null)

      const extraction = {questionnaire_id: questionnaireId}

      if (users.length > 0) extraction.users = users
      if (severity !== null) extraction.severity = severity

      return extraction
    },
    reloadGrid(filters) {
      const filtersExtracted = this.extractFilters(filters)

      if (filtersExtracted.questionnaire_id) {
        const customFilters = [{key:'questionnaire_id', value: filtersExtracted.questionnaire_id}]

        if ('users' in filtersExtracted) customFilters.push({
          key: 'users', value: filtersExtracted.users
        })

        if ('severity' in filtersExtracted) customFilters.push({
          key: 'severity', value: filtersExtracted.severity
        })

        this.grid().fillCustomFilters(
          customFilters
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['grid']
    }

  }
}
</script>

<style lang="scss">
._event-low,
._event-medium,
._event-high {
  @apply font-bold;
}

._event-low {
  color: rgba(var(--vs-success), 1) !important;
}

._event-medium {
  color: rgba(var(--vs-warning), 1) !important;
}

._event-high {
  color: rgba(var(--vs-danger), 1) !important;
}

</style>
