var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
    _c("legend", { staticClass: "px-1 font-bol" }, [_vm._v(" Filtros ")]),
    _c(
      "div",
      { staticClass: "flex items-center p-2 flex-wrap gap-4" },
      [
        _c("select-suggestion", {
          key: _vm.questionnaire_id,
          ref: "select_questionnaire",
          class:
            "flex-grow w-full " + (_vm.invalidQuestionnaire ? "required" : ""),
          attrs: {
            model: "ContentQuestionnaire",
            label: "Questionário",
            max: 20,
            itemTextFormat: function (model) {
              return _vm._.get(model, "name", _vm._.get(model, "title", "UNK"))
            },
            color: "dark",
            appendClearOption: true,
            promptText: "Informe o nome para pesquisar",
            placeholderText: "Digite o nome do questionário",
          },
          on: { clearSelection: _vm.clearFilters },
          model: {
            value: _vm.values.questionnaire,
            callback: function ($$v) {
              _vm.$set(_vm.values, "questionnaire", $$v)
            },
            expression: "values.questionnaire",
          },
        }),
        _c(
          "div",
          { staticClass: "flex-grow" },
          [
            _c("user-repository", {
              staticClass: "w-full",
              attrs: { userRepository: _vm.userRepository },
              model: {
                value: _vm.values.user,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "user", $$v)
                },
                expression: "values.user",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "grid" }, [
          _c("label", { staticClass: "col-span-3 p- font-medium text-grey" }, [
            _vm._v(" " + _vm._s(_vm.$t("criticidade-dos-eventos")) + " "),
          ]),
          _c("div", { staticClass: "flex gap-2 flex-grow-0 select-none" }, [
            _c(
              "div",
              {
                class: _vm.severityFilterVisuals("L", "black"),
                on: {
                  click: function ($event) {
                    return _vm.filterSeverity("L")
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "h-4 w-4 text-success",
                  attrs: { icon: "exclamation" },
                }),
                _c("span", { staticClass: "text-success" }, [_vm._v("Baixa")]),
              ],
              1
            ),
            _c(
              "div",
              {
                class: _vm.severityFilterVisuals("M", "black"),
                on: {
                  click: function ($event) {
                    return _vm.filterSeverity("M")
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "h-4 w-4 text-warning",
                  attrs: { icon: "exclamation-circle" },
                }),
                _c("span", { staticClass: "text-warning" }, [_vm._v("Média")]),
              ],
              1
            ),
            _c(
              "div",
              {
                class: _vm.severityFilterVisuals("H", "black"),
                on: {
                  click: function ($event) {
                    return _vm.filterSeverity("H")
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "h-4 w-4 text-danger",
                  attrs: { icon: "exclamation-triangle" },
                }),
                _c("span", { staticClass: "text-danger" }, [_vm._v("Alta")]),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex gap-1 ml-auto" },
          [
            _c(
              "vs-checkbox",
              {
                staticClass: "text-sm smaller-checkbox",
                model: {
                  value: _vm.values.autoFilter,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "autoFilter", $$v)
                  },
                  expression: "values.autoFilter",
                },
              },
              [_vm._v("Automático")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "py-auto px-4",
                attrs: { disabled: !_vm.values.questionnaire },
                on: { click: _vm.emitFetch },
              },
              [_vm._v("Filtrar")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "py-auto px-4",
                attrs: { type: "border", disabled: !_vm.isFiltering },
                on: { click: _vm.clearFilters },
              },
              [_vm._v("Limpar")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }